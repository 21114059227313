<template>
  <v-form>
    <v-row align="center" justify="center">
      <v-col cols="12">
        <div class="fn24-weight7 text-center" style="color: #1a347f">
          My Appointments
        </div>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="tab" active-class="active" centered fixed-tabs>
          <v-tab v-for="(item, index) in items" :key="index" width="160">
            <span style="color: #1a347f" class="mt-3 fn12-weight7 text-center">
              {{ item.name }}
            </span>
          </v-tab>
        </v-tabs>

        <!-- createdAt: "2022-07-06T09:22:07.017Z"
date: "2022-07-03"
description: "โดนัทจองหมอ"
doctor: Object
doctorId: 19
file_upload: null
id: 5
meeting_link: null
time: "09:00:00"
title: "โดนัทจองหมอ"
updatedAt: "2022-07-06T09:22:07.017Z"
user: Object
userId: 53 -->
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(item, index) in items" :key="index">
            <div
              v-for="(object, index) in item.objects"
              :key="index"
              class="mt-2"
            >
              <v-card
                elevation="2"
                class="rounded-lg mx-auto ma-1"
                min-width="280"
                max-width="354"
                max-height="130"
                height="130"
              >
                <v-row class="pt-5 mt-4">
                  <v-col cols="4" class="">
                    <v-row align="center" justify="center">
                      <v-avatar size="80">
                        <v-img
                          :src="object.doctor.image_profile"
                          class="mx-4 my-4"
                        />
                      </v-avatar>
                    </v-row>
                  </v-col>
                  <v-col cols="8" class="">
                    <v-row class="">
                      <span class="fn14-weight7 mt-0 ml-5">
                        {{ object.doctor.first_name }}
                        {{ object.doctor.last_name }}
                      </span>
                    </v-row>
                    <v-row>
                      <span class="fn12 mt-2 ml-5">
                        {{ object.date }} {{ object.time }}
                      </span>
                    </v-row>
                  </v-col>
                </v-row>
                <v-card-actions v-if="object.status" class="px-2 mt-n5 fn14">
                  <div class="mx-10"></div>
                  <div style="color: #9f9f9f" class="mx-10">Canceled</div>
                </v-card-actions>
                <v-card-actions
                  v-else
                  class="px-2 mt-n5 fn14"
                  style="font-weight: 600"
                >
                  <v-spacer></v-spacer>
                  <div style="color: #1a347f">
                    Click here to start <a-icon type="right" />
                  </div>
                  <div
                    style="color: #e40808"
                    class="mx-4"
                    @click="cancelmeeting(object)"
                  >
                    Cancel
                  </div>
                </v-card-actions>
              </v-card>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      persistent
      :overlay-opacity="0.9"
      min-width="375"
    >
      <a-icon
        type="close"
        class="text-right white--text"
        @click="dialog = false"
      />
      <v-card
        class="pt-8 pb-2 mx-auto"
        max-width="375"
        min-height="515"
        max-height="515"
      >
        <div class="fn24-weight7 mx-2 text-center" style="color: #1a347f">
          Are you sure that you want to cancel?
        </div>
        <div class="text-center">
          <!-- src="@/assets/profile/no1.png" -->
          <v-avatar height="84px" width="84px">
            <img
              v-if="docData.doctor"
              :src="docData.doctor.image_profile"
              class="my-4"
              style="display: block; margin-left: auto; margin-right: auto"
            />
          </v-avatar>
          <div
            class="fn16-weight7 text-center"
            style="color: #1a347f"
            v-if="docData.doctor"
          >
            <!-- Dr.Johnna Dekdee -->
            <!-- {{ docData.doctor.first_name }} {{ docData.doctor.last_name }} -->
          </div>
          <v-divider class="my-4"></v-divider>
          <v-row no-gutters>
            <v-col cols="6" class="text-center">
              <span class="fn16-weight7" v-if="docData.doctor"
                >{{ docData.day }} {{ docData.date }}</span
              >
            </v-col>
            <v-col cols="6" class="text-center">
              <span class="fn16-weight7">{{ docData.time }}</span>
            </v-col>
          </v-row>
          <v-divider class="my-4"></v-divider>
          <div
            class="mx-6 fn12-weight7 text-center my-4"
            style="color: #d72626"
          >
            *You need to schedule a new appointment if you want to consult.
          </div>
        </div>
        <v-btn
          width="270"
          max-width="346"
          height="57"
          dark
          elevation="0"
          class="rounded-lg mx-auto text-capitalize"
          style="
            background: #fb4040;
            display: block;
            margin-left: auto;
            margin-right: auto;
          "
          @click="DeleteAppointment(docData)"
        >
          Cancel this appointment
        </v-btn>
        <v-btn
          width="270"
          max-width="346"
          height="57"
          elevation="0"
          text
          class="rounded-lg mx-auto text-capitalize"
          @click="dialog = false"
          style="
            color: #1a347f;
            display: block;
            margin-left: auto;
            margin-right: auto;
          "
        >
          Back
        </v-btn>
      </v-card>
    </v-dialog>
  </v-form>
</template>
<script>
import { Decode, Encode } from "@/services";
export default {
  data() {
    return {
      tab: null,
      dialog: false,
      items: [
        {
          name: "Upcoming",
          objects: [
            // {
            //   name: "Online Consulting with Dr. Johnna Dekdee",
            //   image: require("@/assets/profile/no1.png"),
            //   time: "Thu, Dec 18 14:00 - 15:00",
            //   status: "",
            // },
          ],
        },
        {
          name: "History",
          objects: [
            // {
            //   name: "Online Consulting with Dr. Johnna Dekdee",
            //   image: require("@/assets/profile/no1.png"),
            //   time: "Thu, Dec 18 14:00 - 15:00",
            //   status: "Canceled",
            // },
          ],
        },
      ],
      search: "",
      count: 0,
      docData: [],
    };
  },
  async created() {
    // await this.getUser();
    await this.getProfile();
  },
  methods: {
    cancelmeeting(object) {
      this.docData = object;
      this.dialog = true;
      console.log("docData", object);
    },
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      if (!response) {
        this.$router.push("Unauthorize");
      } else if (!response.data.data.species_type) {
        this.$router.push("ManageSpecies");
      }
      console.log("user Data", response.data.data);
      this.userData = response.data.data;
      this.tel = this.userData.tel ? this.userData.tel : "";
      this.postcode = this.userData.postcode ? this.userData.postcode : "";
      await this.getAllAppointment();
    },
    async getAllAppointment() {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/appoinments?userId=${this.userData.id}`
        // `http://localhost:8081/appoinments?userId=${this.userData.id}`
      );
      console.log("getAllAppointment", response.data.data);
      let array = response.data.data;
      this.items[0].objects = response.data.data;
      // for (let i in array) {
      //   this.items[i].objects = array[i];
      //   this.items[i].objects.push(array[i])
      // }
      console.log("this.items", this.items);
    },
    UpdateVoucher(val) {
      localStorage.setItem("Voucherdata", Encode.encode(val));
      this.$router.push("EditVoucher");
    },
    ViewVoucher(val) {
      localStorage.setItem("Voucherdata", Encode.encode(val));
      this.$router.push("ViewVoucher");
    },
    async DeleteAppointment(val) {
      this.$swal({
        text: "คุณต้องการยกเลิกใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการยกเลิก",
      }).then(async (result) => {
        if (result.isConfirmed) {
          var user = JSON.parse(
            Decode.decode(localStorage.getItem("userBiData"))
          );
          const auth = {
            headers: {
              Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
            },
          };
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/appoinments/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ยกเลิกสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.dialog = false;
          this.getAllAppointment();
        }
      });
    },
    goToCreate() {
      this.$router.push("createVoucher");
    },
  },
};
</script>

<style scoped>
.active {
  color: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
